import React from 'react'
import moment from 'moment-timezone'

export default class Show extends React.Component {

  constructor (props) {
    super(props)
    this.props = props
    this.state = {}
  }

  render () {
    if (!this.state.show) return null
    return (
      <div className="row">
        <div className="col-3 col-sm-4">{this.renderPresenterImage()}</div>
        {this.renderShow()}
      </div>
    )
  }

  renderShow () {
    if (this.state.show.current) return (
      <div className="presenter-info col-9 col-sm-8 text-start">
        <strong>{this.state.show.current.name}</strong>
        {this.renderPresenterName()}
        <br/>
        {moment(this.state.show.current.start_timestamp, 'YYYY-MM-DD HH:mm:ss').tz('Europe/London').format('h:mma')}
        &nbsp;-&nbsp;
        {moment(this.state.show.current.end_timestamp, 'YYYY-MM-DD HH:mm:ss').tz('Europe/London').format('h:mma')}
      </div>
    )
    return (
      <div className="presenter-info col-9 col-sm-8 text-start">
        <strong>Random Play</strong>
        {this.state.show.livePresenter ? <><br/><em>{this.state.show.livePresenter.name}</em></> : ''}
      </div>
    )
  }

  renderPresenterImage () {
    if (this.state.show.livePresenter) return (<img src={this.state.show.livePresenter.image} className="rounded-circle" />)
    if (this.state.show.current.image) return (<img src={this.state.show.current.image} className="rounded-circle" />)
    return (<img src="https://images.whitecliffsradio.co.uk/presenters/placeholder.png" className="rounded-circle" />)
  }

  renderPresenterName () {
    if (!this.state.show.livePresenter && !this.state.show.current.presenter_name) return null
    return (
      <>
        <br/><em>{this.state.show.livePresenter ? this.state.show.livePresenter.name : this.state.show.current.presenter_name}</em>
      </>
    )
  }

  componentDidMount () {
    setInterval(() => {
      this.fetchShow()
    }, 60000)
    this.fetchShow()
  }

  async fetchShow () {
    let path = '/dynamic-updates/show'
    if (this.props.next) path = '/dynamic-updates/next-show'
    const raw = await fetch(path)
    const json = await raw.json()
    if (this.props.next) return this.setState({show: {current: json.nextShow}})
    return this.setState({show: json})
  }
 
}