document.addEventListener('DOMContentLoaded', () => {

  const openPlayerLink = document.querySelectorAll('.openPlayer')

  if (!openPlayerLink.length) return
 
  openPlayerLink.forEach(link => {
    link.addEventListener('click', e => {
      // const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)
      // if (isMobile) return
      e.preventDefault()
      return window.open('/player', null, 'height=250,width=450,scrollbars=no')
    })
  })

})