import { Notifier } from '@airbrake/browser'

const getEnv = () => {
  const development = /localhost/
  const staging = /staging.*/
  if (development.test(window.location.hostname)) return 'development'
  if (staging.test(window.location.hostname)) return 'staging'
  return 'production'
}

window.airbrake = new Notifier({
  projectId: 1,
  projectKey: '0432961f6eec445b18fa74da1292c6d1',
  reporter: 'xhr',
  remoteConfig: false,
  host: 'https://errbit.thetechcrew.co.uk',
  environment: getEnv()
})