import React from 'react'
import { createRoot } from 'react-dom/client'

import './js/airbrake'

import './less/index.less'

import './js/openPlayer.js'
// import './js/player.js'
import './js/lightbox'

import Show from './Components/Show.js'

document.addEventListener('DOMContentLoaded', () => {
  const shows = document.querySelectorAll('show')
  if (shows.length) {
    shows.forEach(show => {
    const root = createRoot(show)
    if (show.getAttribute('next') && show.getAttribute('next') === 'true') return root.render(<Show next={true} />)
      return root.render(<Show/>)
    })
  }
})